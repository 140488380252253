export enum InfoFindingCode {
  INFO_CSP_DEPENDENCIES = 'INFO_CSP_DEPENDENCIES',
  INFO_SPF_HARD_FAIL = 'INFO_SPF_HARD_FAIL',
  INFO_HOST_NOT_FOUND = 'INFO_HOST_NOT_FOUND',
  INFO_HTTP_TIMEOUT = 'INFO_HTTP_TIMEOUT',
  INFO_DNS_TIMEOUT = 'INFO_DNS_TIMEOUT',
  INFO_CHAIN_TLD_CHANGED = 'INFO_CHAIN_TLD_CHANGED',
  INFO_CHAIN_LIMIT_EXCEEDED = 'INFO_CHAIN_LIMIT_EXCEEDED',
  INFO_HTTP_5XX = 'INFO_HTTP_5XX',
}

export type GenericInfoFinding = {
  code: InfoFindingCode;
};

// INFO_CSP_DEPENDENCIES
export type CSPDependencyInfoFinding = {
  code: InfoFindingCode.INFO_CSP_DEPENDENCIES;
  context: {
    csp: Record<string, { duplicate: boolean; fields: string[] }>;
  };
};

// INFO_SPF_HARD_FAIL
export type SPFHardFailInfoFinding = {
  code: InfoFindingCode.INFO_SPF_HARD_FAIL;
  context: {
    domains: string[];
  };
};

// Host not found info finding
export type HostNotFoundInfoFinding = {
  code: InfoFindingCode.INFO_HOST_NOT_FOUND;
};

// Timeout info findings
export type HTTPTimeoutInfoFinding = {
  code: InfoFindingCode.INFO_HTTP_TIMEOUT;
};
export type DNSTimeoutInfoFinding = {
  code: InfoFindingCode.INFO_DNS_TIMEOUT;
};

// Redirection info findings
export type ChainTLDChangedRedirectionInfoFinding = {
  code: InfoFindingCode.INFO_CHAIN_TLD_CHANGED;
  context: {
    via: string[];
  };
};
export type ChainLimitExceededRedirectionInfoFinding = {
  code: InfoFindingCode.INFO_CHAIN_LIMIT_EXCEEDED;
  context: {
    via: string[];
  };
};

// HTTP 5XX error info finding
export type HTTP5XXInfoFinding = {
  code: InfoFindingCode.INFO_HTTP_5XX;
  context: {
    statusCode: number;
    statusText: string;
  };
};

export type InfoFinding =
  | GenericInfoFinding
  | CSPDependencyInfoFinding
  | SPFHardFailInfoFinding
  | HostNotFoundInfoFinding
  | HTTPTimeoutInfoFinding
  | DNSTimeoutInfoFinding
  | ChainTLDChangedRedirectionInfoFinding
  | ChainLimitExceededRedirectionInfoFinding
  | HTTP5XXInfoFinding;

// Type guard function
export function isFindingType<T extends InfoFinding>(
  finding: InfoFinding,
  expectedCode: T['code'],
): finding is T {
  return finding.code === expectedCode;
}
