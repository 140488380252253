import type { Engagement } from '@/types/models/engagements';
import { request } from '@/api/request';
import { isAuthenticatedRef, isAuthenticatedByRLRef } from '@/modules/auth/auth';
import type { Organisation } from '@/types/models/organisation';
import type { UserBasicInfo } from '@/types/models/user';

const getCurrentOrg = () => request<Organisation>('GET', '/org');

export type UpdateOrganisationVariables = unknown;
const editCurrentOrg = (orgPatch: UpdateOrganisationVariables) =>
  request('PATCH', '/org', orgPatch);

const becomeSupplier = () => request('POST', '/org/supplier');

const becomeClient = () => request('POST', '/org/client');

export type UsersResponse = {
  usersByID: Record<string, UserBasicInfo>;
};
const getOrgUsers = () => request('GET', '/users');

const getOrgEngagements = () => request<Engagement[]>('GET', '/org/engagements');

const api = {
  getCurrentOrg,
  editCurrentOrg,
  becomeSupplier,
  becomeClient,
  getOrgUsers,
  getOrgEngagements,
};
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  isAuthenticatedRef.value = true;
  isAuthenticatedByRLRef.value = true;
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
