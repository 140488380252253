import { InfoFindingCode } from './types';

export default {
  en: {
    [InfoFindingCode.INFO_SPF_HARD_FAIL]: {
      finding: "Uses '-all' instead of recommended '~all'",
      explanation:
        'The domain uses a strict rejection policy ("-all") for unauthorised senders. While secure, this may block legitimate emails if the SPF record is not carefully maintained. The domains related are:',
    },
    [InfoFindingCode.INFO_CSP_DEPENDENCIES]: {
      finding: 'CSP has {n} dependency | CSP has {n} dependencies',
      explanation:
        'The detected Content Security Policy (CSP) includes various external dependencies. These define which resources the website allows for scripts, styles, and other content, helping to mitigate security risks like XSS attacks. Review the listed dependencies to ensure they align with your security policies.',
    },
    [InfoFindingCode.INFO_CHAIN_LIMIT_EXCEEDED]: {
      finding: 'Redirect limit exceeded',
      explanation:
        'The resource accessed has too many redirects, exceeding the allowed limit. This may be due to a misconfiguration or an infinite redirect loop.',
      redirectionChainTitle: 'The redirected domains are:',
    },
    [InfoFindingCode.INFO_CHAIN_TLD_CHANGED]: {
      finding: 'Top level domain changed in redirection',
      explanation:
        'The resource has been redirected to a different top level domain. . This may indicate a domain migration, misconfiguration, or a potential security concern.',
      redirectionChainTitle: 'The redirected domains are:',
    },
    [InfoFindingCode.INFO_HTTP_TIMEOUT]: {
      finding: 'No timely response during HTTP request',
      explanation:
        'The server took too long to respond to the request. This may be due to high server load, network issues, or the server being down.',
    },
    [InfoFindingCode.INFO_DNS_TIMEOUT]: {
      finding: 'No timely response during DNS lookup',
      explanation:
        'The DNS resolver did not respond within the expected time. This could be caused by network issues, an unresponsive DNS server, or misconfigured DNS settings.',
    },
    [InfoFindingCode.INFO_HOST_NOT_FOUND]: {
      finding: 'Domain does not exist',
      explanation:
        'The domain that is scanned does not exist in the DNS system. This could be due to a typo, an unregistered domain, or a DNS configuration issue.',
    },
    [InfoFindingCode.INFO_HTTP_5XX]: {
      finding: 'Server Error (5XX)',
      explanation:
        'The server responded with a 5XX error indicating it encountered an issue processing the request.',
      receivedTitle: 'Received',
      received: '{statusCode} {statusText}',
      explanationTitle: 'Explanation',
      explanations: {
        500: "This indicates an unexpected condition was encountered that prevented the server from fulfilling the request. It's often caused by unhandled exceptions, misconfigurations, or runtime errors in the application.",
        501: 'The server does not support the functionality required to fulfill the request. This may be due to unsupported HTTP methods or missing server-side logic.',
        502: 'The server, while acting as a gateway or proxy, received an invalid response from an upstream server. This usually indicates issues in inter-service communication or upstream service failure.',
        503: 'The server is currently unable to handle the request due to temporary overloading or scheduled maintenance. This is often a transient issue, but for recurring instances, please investigate the assets added',
        504: 'The server, while acting as a gateway or proxy, did not receive a timely response from an upstream server. This may indicate latency or network issues between services.',
        505: 'The server does not support the HTTP protocol version used in the request. This may be caused by outdated or misconfigured clients.',
        custom:
          'This is a non-standard or custom server error code. It typically represents an internal failure specific to the application or infrastructure.',
      },
    },
  },
};
